<script lang="jsx">

export default {
  name: 'tojoy-approval-process',
  props: {
    leveNodeData: {
      type: Array,
      default: () => {
        return []
      },
    }
  },
  data() {
    return {
      defaultLeveNode: {
        isMode: true /**是否功能按钮 */,
        color: '#3D7EFF',
        size: 'large',
      },
      funcType:'data',
      node: [],
    };
  },
  mounted() {
    this.node =  this.leveNodeData;

  },
render() {
    const {$scopedSlots} = this;
    return (

    <div class="tojoy-technological">
     <el-timeline>
        {
          this.node.length &&  this.node.map((item,index) => {
            const { icon, color="#3D7EFF", size='large', timestamp,nodeName,isMode } = item
            return (
              <el-timeline-item
                  icon={icon}
                  color={color}
                  size={size}
                  timestamp={timestamp}>
              <div class={`tojoy-technological__title ${isMode && 'tojoy-technological__button'}`}>

                  <span> {nodeName } </span>

              </div>
               {
                    !isMode &&  $scopedSlots.exec({
                        data: item
                      })
                }

             </el-timeline-item>
            )
          })
        }
      </el-timeline>
     </div>
    )
  }
  }
</script>

<style lang="scss">
.#{$css-prefix}-technological {
  display: flex;

  &__title {
    position: relative;
    .del {
      cursor: pointer;
      position: absolute;
      color: $darkgrey;
      top: 0;
      right: 0;
      i {
        margin-right: 5px;
      }
    }
  }
  &__button {
    cursor: pointer;
    color: $blue;
    i {
      font-weight: 600;
      margin-right: 3px;
    }
  }
  .el-timeline-item__node--large {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    border: 3px solid $white;
  }

  .el-timeline {
    width: 100%;
  }

  .el-timeline-item__wrapper {
    top: -2px;
    padding-left: 20px;
    box-sizing: border-box;
  }
}
</style>
